import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { FormboxContainer } from 'components/Form/Formbox';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { TextboxGroup2FA } from '../index';
import { variants } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/TextboxGroup" title="TextboxGroup" mdxType="PageTitle" />
    <h2 {...{
      "id": "two-factor-authentication"
    }}>{`Two factor authentication`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <StateWrapper initialState=\"\">\n        {({ state, setState }) => (\n          <Rhythm py={3}>\n            <TextboxGroup2FA\n              inputWidth={64}\n              length={6}\n              onChange={(event, value) => setState(value)}\n              size=\"7xlarge\"\n              value={state}\n              variant={variant}\n            />\n          </Rhythm>\n        )}\n      </StateWrapper>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      FormboxContainer,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      TextboxGroup2FA,
      variants,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <StateWrapper initialState="" mdxType="StateWrapper">
          {({
            state,
            setState
          }) => <Rhythm py={3} mdxType="Rhythm">
              <TextboxGroup2FA inputWidth={64} length={6} onChange={(event, value) => setState(value)} size="7xlarge" value={state} variant={variant} mdxType="TextboxGroup2FA" />
            </Rhythm>}
        </StateWrapper>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "two-factor-authentication-labeled"
    }}>{`Two factor authentication, labeled`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <StateWrapper initialState=\"\">\n        {({ state, setState }) => (\n          <Rhythm grouped py={3}>\n            <FormboxContainer\n              focused\n              as=\"label\"\n              label=\"Two-factor code\"\n              size=\"7xlarge\"\n              type=\"input\"\n              variant=\"minimal\"\n            >\n              <TextboxGroup2FA\n                inputWidth={64}\n                length={6}\n                onChange={(event, value) => setState(value)}\n                size=\"7xlarge\"\n                value={state}\n                variant={variant}\n              />\n            </FormboxContainer>\n          </Rhythm>\n        )}\n      </StateWrapper>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      FormboxContainer,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      TextboxGroup2FA,
      variants,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <StateWrapper initialState="" mdxType="StateWrapper">
          {({
            state,
            setState
          }) => <Rhythm grouped py={3} mdxType="Rhythm">
              <FormboxContainer focused as="label" label="Two-factor code" size="7xlarge" type="input" variant="minimal" mdxType="FormboxContainer">
                <TextboxGroup2FA inputWidth={64} length={6} onChange={(event, value) => setState(value)} size="7xlarge" value={state} variant={variant} mdxType="TextboxGroup2FA" />
              </FormboxContainer>
            </Rhythm>}
        </StateWrapper>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "two-factor-authentication-joined"
    }}>{`Two factor authentication, joined`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <StateWrapper initialState=\"\">\n    {({ state, setState }) => (\n      <TextboxGroup2FA\n        inputWidth={32}\n        length={6}\n        onChange={(event, value) => setState(value)}\n        size=\"3xlarge\"\n        style={{ \'--textbox-group-input-spacing\': \'0px\' }}\n        value={state}\n        variant=\"filled\"\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      FormboxContainer,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      TextboxGroup2FA,
      variants,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState="" mdxType="StateWrapper">
      {({
            state,
            setState
          }) => <TextboxGroup2FA inputWidth={32} length={6} onChange={(event, value) => setState(value)} size="3xlarge" style={{
            '--textbox-group-input-spacing': '0px'
          }} value={state} variant="filled" mdxType="TextboxGroup2FA" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={TextboxGroup2FA} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      